import "./Sidebar.css"
import logoIcon from "../../assets/logos/sidebar_logo.svg"
import iconA from "../../assets/icons/a.svg"
import iconB from "../../assets/icons/b.svg"
import iconC from "../../assets/icons/c.svg"
import iconD from "../../assets/icons/d.svg"
import iconE from "../../assets/icons/e.svg"
import iconF from "../../assets/icons/f.svg"
import iconG from "../../assets/icons/g.svg"
import iconH from "../../assets/icons/h.svg"
import { Link } from "react-router-dom"
import { useContext, useState } from "react"
import { MenuContext } from "../../setup/context/MenuContext"
import { enablePageScroll } from "../../helpers/setPageScroll"
import { handleSidebarVisibility } from "../../helpers/dist/setPageScroll"

const Sidebar = () => {
    const { menuOpen, setMenuOpen } = useContext(MenuContext)
    let [sidebarVisible, setSidebarVisible] = useState(true)
    handleSidebarVisibility(setSidebarVisible, setMenuOpen)

    return (
        <>
            {menuOpen && <div className="sidebar" style={{ "display": `${sidebarVisible ? "flex" : "none"}` }}>
                <div className="wrapper">
                    <div className="close_sidebar">
                        <span onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>X</span>
                    </div>
                    <div className="logo">
                        <img src={logoIcon} alt="Coinclass logo" />
                    </div>
                    <div className="links">
                        <ul>

                            <Link className="react_link" to="/My_locks_list" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconG} alt="Create lock icon" />
                                    </div>
                                    <span className="link_text">My lock(s)</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/Create_lock" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconG} alt="Create lock icon" />
                                    </div>
                                    <span className="link_text">Create lock</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/Create_presale" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconH} alt="Create presale icon" />
                                    </div>
                                    <span className="link_text">Create Presale</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/Presale_list" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconA} alt="Presale icon" />
                                    </div>
                                    <span className="link_text">Presale List</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/Create_token" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconB} alt="Create token icon" />
                                    </div>
                                    <span className="link_text">Create token</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/Airdrop" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconC} alt="Airdrop icon" />
                                    </div>
                                    <span className="link_text">Airdrop</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconD} alt="Doc icon" />
                                    </div>
                                    <span className="link_text">Doc</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconE} alt="Telegram icon" />
                                    </div>
                                    <span className="link_text">Telegram</span>
                                </li>
                            </Link>

                            <Link className="react_link" to="/" onClick={() => { setMenuOpen(!menuOpen); enablePageScroll() }}>
                                <li>
                                    <div className="icon">
                                        <img src={iconF} alt="Twitter icon" />
                                    </div>
                                    <span className="link_text">Twitter</span>
                                </li>
                            </Link>



                        </ul>
                    </div>
                </div>
            </div>}
        </>
    )
}

export default Sidebar