import Footer from "../../../components/Footer/Footer";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import "./Page_1.css";
import { useContext, useEffect, useState } from "react";
import { PresaleContext } from "../../../setup/context/PresaleContext";
import { Link } from "react-router-dom";
import WalletContext from "../../../setup/context/walletContext";
import { ethers } from "ethers";
import StandardToken from "../../../ABIs/StandardToken.json";
import connectWallet from "../../../utility/connect";
import { Navigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Page1 = () => {
  const LAUNCHPAD_FACTORY_ADDRESS =
    "0xCeEB7e52DCF99D9F06Ac71a5bD4Aecd77C003580";

  const {
    tokenAddress,
    setTokenAddress,
    tokenName,
    setTokenName,
    tokenDecimal,
    setTokenDecimal,
    tokenSymbol,
    setTokenSymbol,
  } = useContext(PresaleContext);

  const { wallet } = useContext(WalletContext);
  const [nextp, setNextPage] = useState(false);
  const [approved, setApproved] = useState(false);

  const [presaleOwnerBalance, setPresaleOwnerBalance] = useState("");

  useEffect(() => {
    nextPage();
  }, [nextp]);
  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  const handleTokenAdd = async (value: any) => {
    if (value && value.length > 6) {
      // const tokenContract = new ethers.Contract(value, [{
      //   "constant": true,
      //   "inputs": [],
      //   "name": "name",
      //   "outputs": [
      //     {
      //       "name": "",
      //       "type": "string"
      //     }
      //   ],
      //   "payable": false,
      //   "stateMutability": "view",
      //   "type": "function"
      // },
      // {
      //   "constant": true,
      //   "inputs": [],
      //   "name": "symbol",
      //   "outputs": [
      //     {
      //       "name": "",
      //       "type": "string"
      //     }
      //   ],
      //   "payable": false,
      //   "stateMutability": "view",
      //   "type": "function"
      // },
      // {
      //   "constant": true,
      //   "inputs": [],
      //   "name": "decimals",
      //   "outputs": [
      //     {
      //       "name": "",
      //       "type": "uint8"
      //     }
      //   ],
      //   "payable": false,
      //   "stateMutability": "view",
      //   "type": "function"
      // }], provider );
      // const tokenContract = new ethers.Contract(value, bep20.abi, provider );
      // const tokenContract = new ethers.Contract(value, bep20.abi, provider );

      const tokenContract = new ethers.Contract(
        value,
        [
          {
            inputs: [],
            name: "decimals",
            outputs: [
              {
                internalType: "uint8",
                name: "",
                type: "uint8",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "symbol",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "name",
            outputs: [
              {
                internalType: "string",
                name: "",
                type: "string",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            inputs: [],
            name: "totalSupply",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            stateMutability: "view",
            type: "function",
          },
          {
            constant: true,
            inputs: [
              {
                internalType: "address",
                name: "account",
                type: "address",
              },
            ],
            name: "balanceOf",
            outputs: [
              {
                internalType: "uint256",
                name: "",
                type: "uint256",
              },
            ],
            payable: false,
            stateMutability: "view",
            type: "function",
          },
        ],
        provider
      );

      const s = await tokenContract.symbol();
      setTokenSymbol(s);
      setTokenDecimal(await tokenContract.decimals());
      const userBalance = await tokenContract.balanceOf(wallet);
      setPresaleOwnerBalance(userBalance.toString());
      setTokenName(await tokenContract.name());
    }
  };

  const approveToken = async () => {
    const conn = await connectWallet.connectWallet();
    const approveloading = toast.loading("Approving Token.... ");

    if (conn) {
      const tokenContract = new ethers.Contract(
        tokenAddress,
        StandardToken.abi,
        conn.signer
      );
      // The amount of tokens to approve
      let amount = "1000000000000000000"; // 1 token in base units

      // The gas limit for the transaction
      let gasLimit = 31000;

      // Approve the tokens
      tokenContract
        .approve(
          LAUNCHPAD_FACTORY_ADDRESS,
          ethers.utils.parseEther(presaleOwnerBalance)
        )
        .then((transaction: any) => {
          console.log("Token approval was successful:", transaction);
          toast.update(approveloading, {
            autoClose: 1000,
            render: "Token approved ",
            isLoading: false,
            type: "success",
          });
          setApproved(true);
        })
        .catch(function (error: any) {
          console.error("Token approval failed:", error);
          toast.update(approveloading, {
            autoClose: 1000,
            render: "Token approval failed.. ",
            isLoading: false,
            type: "success",
          });
        });
    }
  };

  const nextPage = () => {
    return <Navigate to="/Create_presale_info"></Navigate>;
  };

  return (
    <div className="page_1">
      <Navbar />
      <Sidebar />
      <ToastContainer position="top-right"/>
      <main className="page_main">
        <div className="items_wrapper">
          <span className="required_label">(*) is a required field</span>
          <div className="input_group_1">
            <div className="group_1_label">
              <span className="mt">Token Address</span>
              <span className="ast">*</span>
            </div>
            <div className="group_1_input">
              <input
                type="text"
                value={tokenAddress}
                onChange={(e) => {
                  setTokenAddress(e.target.value);
                  handleTokenAdd(e.target.value);
                  console.log(tokenAddress);
                }}
              />
            </div>
            <span className="group_1_note">Create pool fee : 1 BNB</span>
          </div>

          <div className="token_info">
            <div className="ti_row">
              <span className="data">Name</span>
              <span className="value">{tokenName}</span>
            </div>

            <div className="ti_row">
              <span className="data">Symbol</span>
              <span className="value">{tokenSymbol}</span>
            </div>

            <div className="ti_row">
              <span className="data">Decimals</span>
              <span className="value">{tokenDecimal}</span>
            </div>
          </div>

          <div className="currency_details">
            <span className="currency">Currency</span>
            <span className="symbol">BNB</span>
          </div>

          <span className="notice">Users will pay with BNB for your token</span>

          <div className="warning_card">
            <span>
              Make sure the token has ‘Exclude transfer fee’ function if it has
              transfer fees.
            </span>
          </div>

          <div className="form_buttons">
            {approved ? (
              tokenDecimal || tokenName || tokenSymbol ? (
                <Link className="react_link" to="/Create_presale_info">
                  <button style={{ background: "green" }}>Next</button>
                </Link>
              ) : (
                ""
              )
            ) : tokenDecimal ||
              tokenName ||
              (tokenSymbol && presaleOwnerBalance.trim() !== "") ? (
              // <Link className="react_link" to="/Create_presale_info" >
              <button onClick={approveToken}>Approve</button>
            ) : (
              // </Link>
              ""
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Page1;
