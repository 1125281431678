import "./LaunchPage.css"
import Footer from '../../components/Footer/Footer'
import Navbar from '../../components/Navbar/Navbar'
import Sidebar from '../../components/Sidebar/Sidebar'
import projectImg from "../../assets/logos/launch.svg"
import { useContext, useEffect, useState } from "react"
import { PresaleContext } from "../../setup/context/PresaleContext"
import { useParams } from "react-router"
import axios from "axios"
import { enablePageScroll, scrollToTop, disablePageScroll } from "../../helpers/setPageScroll"
import WalletContext from "../../setup/context/walletContext"

const LaunchPage = () => {
    const params = useParams();
    const [isCreator, setIsCreator] = useState<Boolean>(false)
    const [data, setData] = useState<any>({})
    const { whitelist, setWhitelist } = useContext(PresaleContext)
    const [editModalOpen, setEditModalOpen] = useState<Boolean>(false)
    let baseUrl: string = 'https://coinclass1.onrender.com';
    const { wallet } = useContext(WalletContext);


    const modalClose = () => {
        enablePageScroll()
        setEditModalOpen(!editModalOpen)
        scrollToTop()
    }
    const submitEditForm = (e: any) => {
        e.preventDefault()
        modalClose()
    }

    useEffect(() => {
        axios.get(`${baseUrl}/projects/${params?.id}`).then((val) => {
            setData(val.data)
            console.log({ val });
        })
        if (data?.admin_wallet ===  wallet) {
            setIsCreator(true)
        }else {
            setIsCreator(false)
        }
    }, [])

    return (
        <div className="launchpage">
            {editModalOpen && <div className="edit_modal">
                <form className="edit_form" onSubmit={submitEditForm}
                >
                    <div className="form_title">
                        <span className="title">Edit data</span>
                        <span className="close" onClick={modalClose}>X</span>
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Website">Website</label>
                        <input type="text" className="modal_form_input" id="Website" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Twitter">Twitter</label>
                        <input type="text" className="modal_form_input" id="Twitter" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Discord">Discord</label>
                        <input type="text" className="modal_form_input" id="Discord" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Reddit">Reddit</label>
                        <input type="text" className="modal_form_input" id="Reddit" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Facebook">Facebook</label>
                        <input type="text" className="modal_form_input" id="Facebook" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Instagram">Instagram</label>
                        <input type="text" className="modal_form_input" id="Instagram" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Telegram">Telegram</label>
                        <input type="text" className="modal_form_input" id="Telegram" />
                    </div>

                    <div className="modal_input_group">
                        <label htmlFor="Github">Github</label>
                        <input type="text" className="modal_form_input" id="Github" />
                    </div>

                    <button type="submit">Update</button>

                </form>
            </div>}
            <Sidebar />
            <Navbar />
            <main className="page_wrapper">
                <div className="top_section">
                    <div className="logo_and_name">
                        <div className="logo_img">
                            <img src={data?.logo} alt="Project image" />
                        </div>
                        <span className="project_name">
                            {data?.name}
                        </span>
                    </div>
                    <div className="description">
                        <span>{data?.description}</span>
                    </div>
                    <div className="buttons">
                        <div className="top">
                            <div className="button" style={{ "backgroundColor": "lightgreen" }}>
                                <div className="circ"></div>
                                <span className="status">Sale Live</span>
                            </div>
                        </div>
                        <div className="bottom">
                            <div className="button a">SAFU</div>
                            <div className="button b">AUDIT</div>
                            <div className="button c">KYC</div>
                        </div>
                    </div>
                </div>

                <div className="section_2">
                    <div className="project_details card">
                        <div className="table">
                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Token Name</span>
                                <span className="r">{data?.name}</span>
                            </div>
                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Token Symbol</span>
                                <span className="r">{data?.symbol}</span>
                            </div>
                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Token Decimals</span>
                                <span className="r">{data?.decimals}</span>
                            </div>
                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Token Address</span>
                                <span className="r address">
                                    <span className="address_span">
                                        {data?.address}
                                    </span>

                                    <span className="warning">
                                        (Do not send BNB to the token address!)
                                    </span>
                                </span>
                            </div>
                            <div className="card_row">
                                <span className="l">Token Supply</span>
                                <span className="r">{data?.totalSupply || " "}</span>
                            </div>
                            <hr className="rule" />

                

                            <div className="card_row">
                                <span className="l">Tokens For Liquidity</span>
                                <span className="r">{data?.liquidity}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Softcap</span>
                                <span className="r">{data?.softcap}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Presale Start Time</span>
                                <span className="r">{data?.startTime}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Presale End Time</span>
                                <span className="r">{data?.endTime}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Listing On</span>
                                <span className="r listing">Coinclass</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Liquidity Percent</span>
                                <span className="r">{data?.liqPercent}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Liquidity Lockup Time</span>
                                <span className="r">{data?.liqLockTime}</span>
                            </div>
                            <hr className="rule" />

                        </div>

                        <div className="ensure">
                            Ensure you are on our official Coinclass website
                        </div>
                    </div>

                    <div className="project_details card right_card">
                        <div className="table">
                            <div className="card_row">
                                <span className="l">Status</span>
                                <span className="r listing">{data?.ended ? 'Ended' : 'in progress'}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Current Rate</span>
                                <span className="r">{data?.presaleRate}</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Total Contributions</span>
                                <span className="r">Coinclass</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Your Contributions Amount</span>
                                <span className="r">Coinclass</span>
                            </div>
                            <hr className="rule" />

                            <div className="card_row">
                                <span className="l">Your reserved Tokens</span>
                                <span className="r">Coinclass</span>
                            </div>
                        </div>

                        <div className="creator_section">
                            <span className="end">Presale will end in</span>
                            <div className="timer">
                                {data?.endTime}
                            </div>
                            <div className="fill_bar">
                                <div className="filler"></div>
                            </div>

                            <div className="filler_values">
                                <span>{data?.bought} BNB</span><span>
                                    {data?.hardcap} BNB</span>
                            </div>

                            <div className="input_section">
                                <span className="amount">Amount</span>
                                <div className="input_wrapper">
                                    <input type="text" />
                                    <div className="max">
                                        <span>Max</span>
                                    </div>
                                </div>
                            </div>

                            <div className="btns">
                                <div>Buy with BNB</div>
                                {!isCreator && <div>Withdraw Contribution</div>}
                            </div>

                            {isCreator ?"":(
                                <div className="creator_section_auth">
                                    <div className="title"> Presale Creator section</div>
                                    <div className="btns">
                                        <div>Finalize Presale</div>
                                        <div onClick={() => {
                                            setEditModalOpen(!editModalOpen);
                                            disablePageScroll(); scrollToTop()
                                        }}>Edit data</div>
                                    </div>

                                    <div className="input_group_3">
                                        <div className="group_3_label">
                                            <span className="mt">Whitelist</span>
                                            <div className="whitelist_select">
                                                <div className="group" onClick={() => setWhitelist(false)}>
                                                    <div className="outer">
                                                        {!whitelist && <div className="inner"></div>}
                                                    </div>
                                                    <span className="group_text">Disable</span>
                                                </div>

                                                <div className="group" onClick={() => setWhitelist(true)}>
                                                    <div className="outer">
                                                        {whitelist && <div className="inner"></div>}
                                                    </div>
                                                    <span className="group_text">Enable</span>
                                                </div>
                                            </div>
                                            <span className="required_label">You can enable/disable whitelist anytime</span>

                                        </div>
                                    </div>

                                    <div className="contributions">
                                        <div className="card_row">
                                            <span className="l">Total Contribution</span>
                                            <span className="r">0 BUSD</span>
                                        </div>
                                        <hr className="rule" />

                                        <div className="card_row">
                                            <span className="l">Total Token Bought</span>
                                            <span className="r">{data?.bought} BUSD</span>
                                        </div>
                                        <hr className="rule" />

                                    </div>
                                </div>
                            )}
                        </div>


                    </div>
                </div>
            </main>

            <Footer />
        </div>
    )
}

export default LaunchPage