import Footer from "../../../components/Footer/Footer"
import Navbar from "../../../components/Navbar/Navbar"
import Sidebar from "../../../components/Sidebar/Sidebar"
import "./Page_2.css"
import { PresaleContext } from "../../../setup/context/PresaleContext"
import { useContext, useState } from "react";
import { Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const scrollToTop = () => {
    window.scrollTo(0, 0)
}

const Page_2 = () => {
    const [hasCompletedForm, setHasCompletedForm] = useState<Boolean>(false);

    

    const {
        tokenName,
        tokenDecimal,
        tokenSymbol
      } = useContext(PresaleContext);
    const { presaleRate, setPresaleRate,
        whitelist, setWhitelist,
        softcap, setSoftcap,
        hardcap, setHardcap,
        minimumBuy, setMinimumBuy,
        maximumBuy, setMaximumBuy,
        refundType, setRefundType,
        router, setRouter,
        liquidity, setLiquidity,
        listingRate, setListingRate,
        startTime, setStartTime,
        endTime, setEndTime,
        vesting, setVesting,
        firstReleaseForPresale, setFirstReleaseForPresale,
        vestingPeriodEachCycle, setVestingPeriodEachCycle,
        presaleTokenReleaseEachCycle, setPresaleTokenReleaseEachCycle,
        liquidityLockup, setLiquidityLockup, } = useContext(PresaleContext)

        function validateForm () {
            console.log("validating")
            if (softcap === "") {
                console.log("Softcap is Blank")
                toast("Softcap is Blank");
              }else if (hardcap === ""){
                toast("Hardcap is blank");
              }else if(minimumBuy === ""){
                toast("Minimum buy is blank");
              }else if(maximumBuy === ""){
                toast("Maximum buy is blank");
              }else if(liquidity === ""){
                toast("Please add liquidity");
              }else if(listingRate === ""){
                toast("Please add listing rate");
              }else if(startTime === ""){
                toast("Please select presale start time");
              }else if(endTime === ""){
                toast("Please select presale endtime");
              }else{
                setHasCompletedForm(true)
              }
        }



    return (
        <div className="page_2">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right"/>
            <main className="page_main">
                <div className="items_wrapper">
                    <div className="form_section">
                        <span className="required_label">(*) is a required field</span>


                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Presale Rate</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={presaleRate} onChange={(e) => setPresaleRate(e.target.value)} />
                            </div>
                            <span className="group_2_note">
                                If I spend 1 BNB how many tokens will I receive?
                            </span>
                        </div>

                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Softcap (BNB)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={softcap} onChange={(e) => setSoftcap(e.target.value)} />
                            </div>
                            <span className="group_2_note">
                                {"Softcap must be >= 50% of Hardcap!"}
                            </span>
                        </div>



                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Minimum Buy (BNB)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={minimumBuy} onChange={(e) => setMinimumBuy(e.target.value)} />
                            </div>
                        </div>


                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Refund Type</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <select name="refund_type" value={refundType} onChange={(e) => setRefundType(e.target.value)} >
                                    <option >Select</option>
                                    <option value="refund">Refund</option>
                                    <option value="Burn">Burn</option>
                                </select>
                            </div>

                        </div>

                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Router</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <select name="refund_type" value={router} onChange={(e) => setRouter(e.target.value)} >
                                    <option value="unselected" selected>--Select-Router-Exchange</option>
                                    <option value="pancakeswap">Pancakeswap</option>
                                </select>
                            </div>

                        </div>


                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Liquidity (%)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input required type="number" placeholder="0" value={liquidity} onChange={(e) => setLiquidity(e.target.value)} />
                            </div>
                        </div>


                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Liquidity lockup (days)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={liquidityLockup} onChange={(e) => setLiquidityLockup(e.target.value)} />
                            </div>
                        </div>
                        <div className="vesting">
                            <input type="checkbox" checked={vesting ? true : false} onChange={() => setVesting(!vesting)} />
                            <span onClick={() => setVesting(!vesting)}>Using Vesting Contributor?</span>
                        </div>
                        {vesting
                            && <>

                                <div className="input_group_2">
                                    <div className="group_2_label">
                                        <span className="mt">First release for presale (percent)</span>
                                        {/* <span className="ast">*</span> */}
                                    </div>
                                    <div className="group_2_input">
                                        <input type="number" placeholder="Ex: 40%" value={firstReleaseForPresale} onChange={(e) => setFirstReleaseForPresale(e.target.value)} />
                                    </div>
                                </div>

                                <div className="input_group_2">
                                    <div className="group_2_label">
                                        <span className="mt">Vesting period each cycle (minutes)</span>
                                        {/* <span className="ast">*</span> */}
                                    </div>
                                    <div className="group_2_input">
                                        <input type="number" placeholder="Enter (days). Ex: 3" value={vestingPeriodEachCycle} onChange={(e) => setVestingPeriodEachCycle(e.target.value)} />
                                    </div>
                                </div>

                                <div className="input_group_2">
                                    <div className="group_2_label">
                                        <span className="mt">Presale token release each cycle (percent)</span>
                                        {/* <span className="ast">*</span> */}
                                    </div>
                                    <div className="group_2_input">
                                        <input type="number" placeholder="Ex: 20%" value={presaleTokenReleaseEachCycle} onChange={(e) => setPresaleTokenReleaseEachCycle(e.target.value)} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>


                    <div className="form_section">
                        <div className="input_group_3">
                            <div className="group_3_label">
                                <span className="mt">Whitelist</span>
                                <div className="whitelist_select">
                                    <div className="group" onClick={() => setWhitelist(false)}>
                                        <div className="outer">
                                            {!whitelist &&
                                                <div className="inner"></div>
                                            }
                                        </div>
                                        <span className="group_text">Disable</span>
                                    </div>

                                    <div className="group" onClick={() => setWhitelist(true)}>
                                        <div className="outer">
                                            {whitelist &&
                                                <div className="inner"></div>
                                            }
                                        </div>
                                        <span className="group_text">Enable</span>
                                    </div>
                                </div>
                                <span className="required_label">You can enable/disable whitelist anytime</span>

                            </div>
                        </div>

                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Hardcap (BNB)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={hardcap} onChange={(e) => setHardcap(e.target.value)} />
                            </div>

                        </div>


                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Maximum buy (BNB)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={maximumBuy} onChange={(e) => setMaximumBuy(e.target.value)} />
                            </div>

                        </div>

                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Listing rate</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="number" placeholder="0" value={listingRate} onChange={(e) => setListingRate(e.target.value)} />
                            </div>
                            <span className="group_2_note_bold">
                                1 BNB = {listingRate} {tokenSymbol}
                            </span>
                        </div>

                        <span className="required_label">
                            Enter the percentage of raised funds that should be allocated to liquidity on (Min 51%, Max 100%)
                        </span>

                        <span className="required_label">
                            If i spend 1BNB, how many tokens will i receive? Usually this amount  is lower than presale rate to allow for a higher listing price on
                        </span>

                        <span className="required_label_white">
                            Select start & end time (UTC) <span>*</span>
                        </span>
                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">Start Time (UTC)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="datetime-local" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
                            </div>
                        </div>

                        <div className="input_group_2">
                            <div className="group_2_label">
                                <span className="mt">End Time (UTC)</span>
                                <span className="ast">*</span>
                            </div>
                            <div className="group_2_input">
                                <input type="datetime-local" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
                            </div>
                        </div>

                    </div>
                </div>
                <div className="form_buttons">
                    <Link className="react_link" to="/Create_presale" onClick={scrollToTop}>
                        <button style={{ "marginRight": "40px" }}>Back</button>
                    </Link>
                    {hasCompletedForm === false ? (
                        <div className="react_link" onClick={validateForm}>
                        <button>Next</button>
                    </div>
                    ) : (
                        ""
                    )}
                    {hasCompletedForm === true ? (
                        <Link className="react_link" to="/Create_presale_social_media" onClick={scrollToTop}>
                        <button>Next</button>
                    </Link>
                    ) : (
                        ""
                    )}

                    
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default Page_2