import Navbar from "../../components/Navbar/Navbar"
import "./Home.css"
import coinclassLogo from "../../assets/logos/coinclass.svg"
import { Link } from "react-router-dom"
import HomepageCard from "../../components/HomepageCard/HomepageCard"
import Footer from "../../components/Footer/Footer"
import Sidebar from "../../components/Sidebar/Sidebar"

const Home = () => {
    return (
        <div className="home">
            <Sidebar />
            <Navbar />
            <main>
                <div className="hero_section">
                    <img src={coinclassLogo} alt="Coinclass logo" />
                </div>

                <div className="home_btns">
                    <div className="btn create">
                        <Link className="react_link" to="/Create_presale">Create</Link>
                    </div>
                    <div className="btn explore">
                        <Link className="react_link" to="/Presale_list">Explore</Link>
                    </div>
                </div>
            </main>

            <div className="homepage_info_section">
                <HomepageCard isAmountCard={false} caption={"Number of presale"} isCircle1={true} borderColor={"#F3BA2F"} circleColor={"#090917"} value="24" />
                <HomepageCard isAmountCard={false} caption={"Number of participants"} isCircle1={false} borderColor={"#090917"} circleColor={"#F3BA2F"} value="50" />
                <HomepageCard isAmountCard={true} caption={"Amounts raised so far"} isCircle1={true} borderColor={"#F3BA2F"} circleColor={"#090917"} value="100" />
            </div>
            <Footer />
        </div>
    )
}

export default Home