import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./PresaleList.css"
import logoMin from "../../assets/logos/logo-min.svg"
import { Link } from "react-router-dom"
import { useEffect, useState } from "react"
import axios from "axios"


const Pool = ({item }:{item: any}) => {
    
    let progress = 100 * (+item?.bought / +item?.hardcap) || 0;
    return <div className="pool">
        <span className="name">{item?.name}</span>
        <div className="rw1">
            <div className="logo">
                <img src={item?.logo} alt="Project logo" />
            </div>
            <div className="btns">
                <div className="safu">SAFU</div>
                <div className="audit">AUDIT</div>
                <div className="kyc">KYC</div>
            </div>
        </div>
        <div className="rw2">
            <span className="s1">{item?.name}</span>
            <span className="s2">{item?.symbol}</span>
        </div>

        <div className="rw3">
            <span>
                Soft/Hard Cap
            </span>

            <span className="val">
                {+item?.softcap} BNB - { +item?.hardcap} BNB
            </span>
        </div>

        <div className="progress">
            <span className="s-a">
                Progress ( { progress } )
            </span>

            <div className="bar">
                <div className="fill" style={{ "width": progress } }></div>
            </div>

            <div className="fill_count">
                <span>100%</span>
            </div>

            <div className="groups">
                <div className="rw">
                    <span className="l">Liquidity %:</span>
                    <span className="r">{item?.liquidity}</span>
                </div>

                <div className="rw">
                    <span className="l">Lockup Time:</span>
                    <span className="r">{item?.liqLockTime} days</span>
                </div>
            </div>

            <div className="status">
                <span className="l">Presale:</span>
                <span>{item?.ended? "filled": 'not filled'}</span>
            </div>

            <div className="vp">

                <Link className="react_link" to={`/launch/${item?._id}`}>
                    <div>View Pool</div>
                </Link>

            </div>
        </div>
    </div>
}

const PresaleList = () => {
    let baseUrl: string = 'https://coinclass1.onrender.com';

    const [ list , setList ] = useState([])
    useEffect(()=>{
        axios.get(`${baseUrl}/projects`).then((data) => {
            console.log({ data})
            setList(data.data);
        })
    }, [])
    return (
        <div className="presale_list">
            <Navbar />
            <Sidebar />

            <main className="list_wrapper">
                <div className="badge_wrapper">
                    <div className="badge">
                        <img src={logoMin} alt="Coinclass logo" />
                        <span>Coinclass</span>
                    </div>
                </div>

                <div className="search_section">
                    <div className="top">
                        <span>All coinclass</span>
                        <div></div>

                        <input type="text" placeholder="Enter token name or token symbol" />
                    </div>
                </div>

                <div className="pools">
                    {list.map((item, i) => {
                        return (
                             <Pool item={item}/>
                        )
                    })}

                </div>

                <div className="vmp">
                    <div>View more pools</div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default PresaleList