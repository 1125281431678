import './App.css';
import Views from './setup/Views';
function App() {
  return (
    <div className="App">
      <Views />
    </div>
  );
}

export default App;
