import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./Airdrop.css"
import logoMin from "../../assets/logos/logo-min.svg"
import { ethers } from "ethers"
import AirdropABI from "../../ABIs/Airdrop.json"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import STANDARDTOKEN_ABI from "../../ABIs/StandardToken.json"
import {useState, useEffect} from 'react'


const Airdrop = () => {
    const [address, setAddress] = useState("");
    const [balance, setBalance] = useState("");
    const [distributionList, setDistributionList] = useState("");
    const [tokencontractDetails, setTokenContractDetails] = useState<any>("");

    const [listOfAddress, setListOfAddress] = useState<any>([]);

    const [listOfValue, setListOfValues] = useState<any>([]);

    const [hasUserApproved, setHasUserApproved] = useState<Boolean>(false);
    const [isUserConnected, setIsUserConnected] =  useState<Boolean>(false)


    const [
    totalNumberOfTokenToAirdrop,
    setTotalNumberOfTokenToAirdrop,
    ] = useState(0);

    const LENGTH_OF_CONTRACT_ADDRESS = 42;
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
     const signer = provider.getSigner()

    const AIRDROP_CONTRACT_ADDRESS = "0x09df1C1841Cb72d0E6429a0a62Bdd44F9a95f096"
    const handleCreateAirdrop  = (tokenAddress: any, receivers: any, amountsToReceive: any)=>{
        return new Promise(async (resolve,reject)=>{
            const airDropContract =  new ethers.Contract(AIRDROP_CONTRACT_ADDRESS, AirdropABI, signer)
    
            const airdropFee =   await airDropContract.dropFee()
    
            try{
                 await airDropContract.airdrop(tokenAddress, receivers, amountsToReceive, {
                     value : airdropFee
                 })
                resolve(true)
            }
            catch(error){
                console.log(error)
                reject(false)
            }
    
    
    
        })
    }
    const getTokenBalance = async (tokenAddress: any, userAddress: any)=>{
        console.log(tokenAddress, userAddress)
        return new Promise(async (resolve,reject)=>{
            try{
                const tokenContract =  new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
                const getDecimals =  tokenContract.decimals()
                const userBalanceRes  =  tokenContract.balanceOf(userAddress)
                const promiseData =  await Promise.all([userBalanceRes, getDecimals])
                console.log(promiseData)
                const userBalance =  promiseData[0].toString() / Math.pow(10, parseInt(promiseData[1].toString()))
                console.log(userBalance)
                resolve(userBalance)
            }
    
            catch(error){
                console.log(error)
                reject(0)
    
            }
            
        })
    }
    const approveToken = (spenderAddress: any, amount: any, tokenAddress: any)=>{
        return new Promise(async (resolve,reject)=>{
            try{
                const tokenContract =  new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
    
                const getDecimals =  await tokenContract.decimals()
        
        
                const parsedAmount= BigInt(amount * Math.pow(10, getDecimals.toString()))
        
                const result =  await tokenContract.approve(spenderAddress, parsedAmount)
    
                await result.wait()
        
                resolve([true, getDecimals.toString()])
            }
    
            catch(error: any){
                console.log(error)
                let erroMessage  =  ""
                if (error.message !== undefined){
                    erroMessage = error.message
                }
                reject([false, erroMessage])
            }
           
        })
    }
    const getTokenDetails = (tokenAddress: any)=>{
        return new Promise(async (resolve,reject)=>{
            const tokenContract =  new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
            console.log(tokenContract)
            const tokenNameReq =  tokenContract.name()
            const tokenSymbolReq =  tokenContract.symbol()
            const tokenDecimalsReq = tokenContract.decimals()
    
            try{
                const tokenDetailsPromise = await Promise.all([tokenNameReq, tokenSymbolReq, tokenDecimalsReq])
                const response =  {
                    tokenAddress : tokenAddress,
                    tokenName : tokenDetailsPromise[0],
                    tokenSymbol : tokenDetailsPromise[1],
                    tokenDecimals : Number(tokenDetailsPromise[2].toString())
                }
        
                resolve(response)
            }
            catch(error){
                console.log(error )
                reject(false)
            }
          
            
        })
    }    
    const getContractDetails = async (tokenAddress: any) => {
        try {
          const tokenDetails: any = await getTokenDetails(tokenAddress);
    
          console.log(tokenDetails);
    
          setTokenContractDetails({ ...tokenDetails });
        } catch (error) {
          alert("There was an error fetching token details ");
        }
      };
    
      const getUserBalance = async (tokenAddress: any) => {
       // const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
       // const signer = provider.getSigner()
        const userWallet = await signer.getAddress()
        try {
          const userBalance: any = await getTokenBalance(tokenAddress, userWallet);
          setBalance(userBalance);
        } catch (error) {
          console.log(error);
        }
      };
    
      const handleApprove = async () => {
        const listOfAddressAndValues = distributionList.split("\n"); // Split by line
    
        if (distributionList.length === 0) {
          toast("No Values in distribution list  yet");
          return;
        }
    
        const approveloading = toast.loading("Approving Tokens.... ");
    
        const listOfAddresses = [];
    
        const listOfValues = [];
    
        let sumOfAmountsToAirdrop = 0;
    
        for (const row of listOfAddressAndValues) {
          const addressAndValue: any = row.split(",");
          console.log(addressAndValue);
          listOfValues.push(
            BigInt(
              addressAndValue[1] * Math.pow(10, tokencontractDetails.tokenDecimals)
            )
          );
          listOfAddresses.push(addressAndValue[0]);
          sumOfAmountsToAirdrop += Number(addressAndValue[1]);
        }
    
        try {
          console.log(listOfAddresses, listOfValues);
    
          await approveToken(
            AIRDROP_CONTRACT_ADDRESS,
            sumOfAmountsToAirdrop,
            address
          );
    
          toast.update(approveloading, {
            autoClose: 1000,
            render: "Airdrop tokens approved ",
            isLoading: false,
            type: "success",
          });
    
          setHasUserApproved(true);
    
          setTotalNumberOfTokenToAirdrop(sumOfAmountsToAirdrop);
    
          setListOfAddress([...listOfAddresses]);
          setListOfValues([...listOfValues]);
        } catch (error) {
          toast.update(approveloading, {
            autoClose: 1000,
            render: "An issue occured while approving tokens ",
            isLoading: false,
            type: "error",
          });
        }
      };
    
      const handleAirdrop = async () => {
        const aidropLoading = toast.loading("Running Airdrop ......");
    
        try {
          await handleCreateAirdrop(address, listOfAddress, listOfValue);
    
          toast.update(aidropLoading, {
            autoClose: 1000,
            render: "Airdrop Completed ",
            isLoading: false,
            type: "success",
          });
        } catch (error) {
          console.log(error);
          toast.update(aidropLoading, {
            autoClose: 1000,
            render: "An issue occured while running airdrop ",
            isLoading: false,
            type: "error",
          });
        }
      };
    
      useEffect(() => {
        if (address.length === LENGTH_OF_CONTRACT_ADDRESS) {
          getContractDetails(address);
          getUserBalance(address);
        }
      }, [address]);
    return (
        <div className="airdrop">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right"/>
            <div className="airdrop_wrapper">
                <div className="badge_wrapper">
                    <div className="badge">
                        <img src={logoMin} alt="Coinclass logo" />
                        <span>Coinclass Airdrop</span>
                    </div>
                </div>

                <span className="note_ad">
                    Airdrop your token to all your users with the click of a button!
                </span>

                <div className="section_ins">
                    <span className="t1">Airdrop Instructions:</span>
                    <span>-Airdrop tokens to as many users as desired</span>
                    <span>-If you are running a sale, make sure tokens are not airdropped until after.</span>
                    <span>-Enter your token address first</span>
                    <span>-Enter a list of users to airdrop followed by amount (comma
                        separated)
                    </span>

                    <span className="fee">Airdrop fees: 0.4 BNB</span>
                </div>

                <div className="section_2">
                    <div className="inputs">
                        <div className="address_entry">
                            <span>Enter Token Address</span>
                            <input type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)} />
                        </div>

                        <div className="token_dt">
                            <div className="gp">
                                <span className="l">Token Name:</span>
                                <span className="r">{tokencontractDetails !== null
                        ? tokencontractDetails.tokenName
                        : ""}</span>
                            </div>

                            <div className="gp">
                                <span className="l">Your Balance:</span>
                                <span className="r">{balance.toLocaleString()}</span>
                            </div>
                        </div>

                        <div className="list">
                            <span>Enter Distribution List</span>
                            <textarea
                            name=""
                            id=""
                            placeholder={`Enter DistributionListribution List \n e.g 0xB71b214Cb885500844365E95CD9942C7276E7fD8,500 \n0x6d6247501b822FD4Eaa76FCB64bAEa360279497f,600
                            `}
                            value={distributionList}
                            onChange={(e) => setDistributionList(e.target.value)}></textarea>
                        </div>

                        <span className="rm">
                            For best results we recommend you do a maximum of 500 Addresses at a time!
                        </span>
                    </div>

                    <div className="inputs rt">

                        <div className="data">
                            <span>Your tokens being airdropped:</span>
                            <span className="value">{totalNumberOfTokenToAirdrop}</span>
                        </div>

                        <div className="btns">
                            <div className="lb">
                                {hasUserApproved === false ? (
                                    <div onClick={handleApprove}>Approve</div>
                                                     ) : (
                                                                             ""
                                                          )}
                                         {hasUserApproved === true ? (
                                <div style={{ "opacity": ".6" }} onClick={handleAirdrop}>
                                                    Airdrop
                                                </div>
                                                    ) : (
                                                      ""
                                                     )}
              </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Airdrop