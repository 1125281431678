import { Link } from "react-router-dom"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./Admin.css"

const Pool = () => {
    return (
        <div className="wrapper">
            <div className="admin_pool">
                <div className="top">
                    <span className="pr_name">EXAMPLE PROJECT NAME</span>
                    <div className="pr_status" style={{ "backgroundColor": "#1ACC78" }}>Live</div>
                </div>
                <div className="mid">
                    <Link to="/" className="react_link">
                        <div className="btn x">Verify Audit</div>
                    </Link>

                    <Link to="/" className="react_link">
                        <div className="btn y">Verify KYC</div>
                    </Link>

                    <Link to="/" className="react_link">
                        <div className="btn z">Verify SAFU</div>
                    </Link>


                </div>
                <div className="btm">
                    <Link to="/" className="react_link">
                        <div className="btn_2 view">View</div>
                    </Link>

                    <Link to="/" className="react_link">
                        <div className="btn_2 cancel">Cancel</div>
                    </Link>
                </div>
            </div>

        </div>
    )
}

const Admin = () => {
    return (
        <div className="admin">
            <Navbar />
            <Sidebar />
            <main className="page_wrapper">
                <header><h1>Pools</h1></header>
                <div className="admin_pools_wrapper">
                    <Pool />
                    <Pool />
                    <Pool />
                    <Pool />
                    <Pool />

                </div>
            </main>
            <Footer />

        </div>
    )
}

export default Admin