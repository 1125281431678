import "./Footer.css"
import telegramIcon from "../../assets/icons/telegram.svg"
import twitterIcon from "../../assets/icons/twitter.svg"
import { Link } from "react-router-dom"

const Footer = () => {
    return (
        <div className="footer">
            <p>  Disclaimer: The information Provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. We accept no liability for any loss occasioned to any person acting or refraining from action as a result of any material that’s provided or published.</p>
            <div className="social">
                <ul>
                    <li>
                        <Link className="react_link" to="/">
                            <div className="ic">
                                <img src={twitterIcon} alt="Twitter icon" />
                            </div>
                            <span>Twitter</span>
                        </Link>
                    </li>
                    <li>
                        <Link className="react_link" to="/">
                            <div className="ic">
                                <img src={telegramIcon} alt="Telegram icon" />
                            </div>
                            <span>Telegram</span>
                        </Link>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Footer