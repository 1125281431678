import "./HomepageCard.css"

type propTypes = {
    borderColor: String,
    circleColor: String,
    value: String | Number,
    isCircle1: Boolean,
    caption: String,
    isAmountCard: Boolean
}

const HomepageCard = ({ borderColor, circleColor, value, isCircle1, caption, isAmountCard }: propTypes
) => {
    return (
        <div className="card" style={{ "border": ` 3px solid ${borderColor}` }}>
            <div className="rings_container">
                <div className="outer_ring">
                    <div className="circle" style={{ "backgroundColor": `${circleColor}` }}>
                        {isAmountCard && <span>{"$" + value}</span>}
                        {!isAmountCard && <span>{"" + value}</span>}
                    </div>
                </div>
                <div className={`inner_ring ${isCircle1 ? "yellowRing" : "blueRing"}`}></div>
            </div>

            <span className="caption" style={{ "color": `${borderColor}` }}>
                {caption}
            </span>
        </div>
    )
}

export default HomepageCard