import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./CreateToken.css"
import { useState, useContext } from "react"
import { ethers } from "ethers"
import STF_ABI from "../../ABIs/StandardTokenFactory.json"
import TFB_ABI from "../../ABIs/TokenFactoryBase.json"
import { ToastContainer, toast } from "react-toastify"
import { Link } from "react-router-dom"

const CreateToken = () => {
    const [tokenCreated, setTokenCreated] = useState<Boolean>(true)
    const STANDARD_TOKEN_FACTORY_ADDRESS = "0x3Be8b6CE8F0Ebf1C9284605F567d07C97B2aE017";
    const TOKEN_FACTORY_BASE_ADDRESS = "0x94D77085BB3B1628Af1953Ee8799247d05036493";

    const [name, setName] = useState("");
    const [symbol, setSymbol] = useState("");
    const [decimals, setDecimals] = useState("");
    const [supply, setSupply] = useState("");
    const [contractAddress, setContractAddress] = useState("0xC57F463BB52c88d6b6A45eF0FCF833Ce630e0cd1");
    const [transactionHash, setTransactionHash] = useState("");
    const [isUserConnected, setIsUserConnected] =  useState<Boolean>(false)



    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
     const signer = provider.getSigner()



    const createStandardToken = (tokenName: string, tokenSymbol: string, tokenDecimals: any, totalSupply: any)=>{
        return new Promise(async(resolve,reject)=>{
    
            try{
                const tokenFactoryBaseContract  =  new ethers.Contract(TOKEN_FACTORY_BASE_ADDRESS, TFB_ABI.abi, signer)
    
                const flatFee =  await tokenFactoryBaseContract.flatFee()
    
                    console.log(flatFee)
    
                    const standardTokenFactory =  new ethers.Contract(STANDARD_TOKEN_FACTORY_ADDRESS, STF_ABI.abi,signer)
    
                    const createTokenReq =  await standardTokenFactory.create(tokenName, tokenSymbol,  tokenDecimals, BigInt(totalSupply * Math.pow(10,tokenDecimals)), {value:flatFee} )
                    
                    const createTokenResponse =  await createTokenReq.wait()
    
                    console.log(createTokenResponse)
    
                    const tokenCreatedEvent =  createTokenResponse.events[1]
    
                    const returnObject  = {
                        address : tokenCreatedEvent.address,
                        transactionHash : tokenCreatedEvent.transactionHash,
                    }
    
                    resolve(returnObject)
            }
            
            catch(error: any){
                if (error.data !== undefined){
                    reject(error.data.message)
                }else{
                    reject("An Error occured")
                }
                
            }
        })
    }
    const handleCreateToken = async () => {
        const loadingToast = toast.loading("Creating Token... ");
        try {
          const createTokenRes: any = await createStandardToken(
            name,
            symbol,
            decimals,
            supply
          );
          console.log(createTokenRes);
    
          setContractAddress(createTokenRes.address);
          setTransactionHash(createTokenRes.transactionHash);
          setTokenCreated(false);
          toast.update(loadingToast, {
            render: "Token Created Successfully",
            autoClose: 1500,
            isLoading: false,
            type: "success",
          });
        } catch (error:any) {
          console.log(error);
          alert("An error occured");
          toast.update(loadingToast, {
            render: error,
            autoClose: 1500,
            isLoading: false,
            type: "error",
          });
        }
      }
      const handleCopyAddress = () => {
        navigator.clipboard.writeText(contractAddress);
        alert("Token Address Copied... ");
      };
    
      const handleOpenTransactionPage = () => {
        // Curently on testnet
        console.log(transactionHash);
        const url = `https://testnet.bscscan.com/tx/${transactionHash}`;
    
        window.open(url, "_blank");
      };
    

    return (
        <div className="create_token">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right" />
            <main className="page_wrapper">
                {tokenCreated && <div className="fill_form">
                    <span className="required_label">(*) is a required field</span>
                    <div className="input_group_1">
                        <div className="group_1_label">
                            <span className="mt">Token Type</span>
                            <span className="ast">*</span>
                        </div>
                        <div className="group_1_input">
                            <select placeholder="Standard">
                                <option value="Standard">Standard</option>
                            </select>
                        </div>
                        <span className="group_1_note">
                            0.3 BNB
                        </span>
                    </div>

                    <div className="input_group_1">
                        <div className="group_1_label">
                            <span className="mt">Name</span>
                            <span className="ast">*</span>
                        </div>
                        <div className="group_1_input">
                            <div className="group_1_input">
                                <input type="text"
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                 placeholder="Ex: Ethereum" />
                            </div>
                        </div>
                    </div>

                    <div className="input_group_1">
                        <div className="group_1_label">
                            <span className="mt">Symbol</span>
                            <span className="ast">*</span>
                        </div>
                        <div className="group_1_input">
                            <div className="group_1_input">
                                <input type="text"
                                value={symbol}
                                onChange={(e) => setSymbol(e.target.value)}
                                placeholder="Ex: ETH" />
                            </div>
                        </div>
                    </div>

                    <div className="input_group_1">
                        <div className="group_1_label">
                            <span className="mt">Decimals</span>
                            <span className="ast">*</span>
                        </div>
                        <div className="group_1_input">
                            <div className="group_1_input">
                                <input type="number"
                                value={decimals}
                                onChange={(e) => setDecimals(e.target.value)}
                                placeholder="Ex: 15" />
                            </div>
                        </div>
                    </div>

                    <div className="input_group_1">
                        <div className="group_1_label">
                            <span className="mt">Total Supply</span>
                            <span className="ast">*</span>
                        </div>
                        <div className="group_1_input">
                            <div className="group_1_input">
                                <input type="number"
                                value={supply}
                                onChange={(e) => setSupply(e.target.value)}
                                placeholder="Ex: 150000000000" />
                            </div>
                        </div>
                    </div>

                    <div className="form_buttons">
                        <button onClick={() => {
                          handleCreateToken();
                        }}>
                            {isUserConnected ? "Create Token" : "Approve"}
                        </button>
                    </div>
                </div>}



                {!tokenCreated &&
                    <div className="fill_form token details">
                        <span className="created">
                            Your token was created!
                        </span>

                        <div className="details_table">
                            <div className="table_row">
                                <span>Name</span>
                                <span>{name}</span>
                            </div>

                            <div className="table_row">
                                <span>Symbol</span>
                                <span>{symbol}</span>
                            </div>

                            <div className="table_row">
                                <span>Total Supply</span>
                                <span>{supply}</span>
                            </div>
                        </div>

                        <span className="address" style={{
                        textOverflow: "ellipsis",
                      }}>
                            {contractAddress}
                        </span>

                        <div className="three_btns">
                            <div className="yl" onClick={() => {
                      handleOpenTransactionPage();
                    }}>View Transaction</div>
                            <div onClick={() => {
                      handleCopyAddress();
                    }}>Copy Address</div>
                    <Link
                    style={{
                        textDecoration: "none",
                      }}
                      to={"/Create_presale"}
                    >
                            <div>Create Coin Pad</div>
                            </Link>
                        </div>
                    </div>}
            </main>

            <Footer />
        </div>
    )
}

export default CreateToken
