import "./Submit_Page.css";
import Navbar from "../../../components/Navbar/Navbar";
import Sidebar from "../../../components/Sidebar/Sidebar";
import { PresaleContext } from "../../../setup/context/PresaleContext";
import { useContext, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import WalletContext from "../../../setup/context/walletContext";
import { ethers } from "ethers";
import BNBLaunchPadFactory from "../../../ABIs/BNBLaunchPadFactory.json";
import connectWallet from "../../../utility/connect";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ProjectDTO } from "../../../dto/project.dto";

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

const Submit_Page = () => {
  const [approved, setApproved] = useState(false);
  const [balance, setBalance] = useState("");

  const { tokenAddress } = useContext(PresaleContext);
  const { wallet } = useContext(WalletContext);

  let baseUrl: string = 'https://coinclass1.onrender.com';

  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
  console.log({ wallet, baseUrl, debug: process.env.REACT_APP_APP_DEBUG });
  const signer = provider.getSigner(wallet);
  // const LAUNCHPAD_FACTORY_ADDRESS = '0xCeEB7e52DCF99D9F06Ac71a5bD4Aecd77C003580';
  const LAUNCHPAD_FACTORY_ADDRESS =
    "0xCeEB7e52DCF99D9F06Ac71a5bD4Aecd77C003580";
  const {
    tokenName,
    tokenDecimal,
    tokenSymbol,
    presaleRate,
    websiteLink,
    whitelist,
    softcap,
    hardcap,
    minimumBuy,
    maximumBuy,
    refundType,
    router,
    liquidity,
    listingRate,
    startTime,
    endTime,
    discordLink,
    description,
    telegramLink,
    instagramLink,
    liquidityLockup,
    facebookLink,
    githubLink,
    logoUrl,
    redditLink,
    twitterLink,
  } = useContext(PresaleContext);

  const create_presale = async () => {
    await createPresale();
    const signer = (await connectWallet.connectWallet()).signer;
    console.log({
      presaleRate,
      websiteLink,
      whitelist,
      softcap,
      hardcap,
      minimumBuy,
      maximumBuy,
      refundType,
      router,
      liquidity,
      listingRate,
      startTime,
      endTime,
      discordLink,
      description,
      telegramLink,
      liquidityLockup,
    });
    const _saleStartTime = new Date(startTime).getTime();
    const _saleEndTime = new Date(endTime).getTime();
    const refV = refundType == "refund" ? 0 : 1;

    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    let gasPrice: string = "";
    let rawGasPrice: string = "";
    web3.eth.getGasPrice().then((result) => {
      gasPrice = web3.utils.fromWei(result, "ether");
      rawGasPrice = result;
    });

    const Contract = new web3.eth.Contract(
      BNBLaunchPadFactory.abi as any,
      LAUNCHPAD_FACTORY_ADDRESS
    );
    const fee = await Contract.methods.flatFee().call();
    console.log({ fee, gasPrice, rawGasPrice });

    Contract.methods
      .create(
        +_saleStartTime,
        +_saleEndTime,
        +minimumBuy * 10 ** 8,
        +maximumBuy * 10 ** 8,
        tokenAddress,
        +hardcap * 10 ** 8,
        +softcap * 10 ** 8,
        +presaleRate,
        +listingRate,
        +liquidity,
        2,
        refV
      )
      .send({ from: wallet, gasLimit: 3000000, value: fee * 10 ** 8 })
      .then((val: any) => {
        console.log("successful", { val });
        createPresale();
        redirectToList();
      })
      .catch((err: any) => {
        console.log({ err });
        console.log("Transaction revert reason:", err.message);
      });

    //4 up and down two shirts
  };

  const data = {
    tokenName,
    tokenDecimal,
    tokenSymbol,
    presaleRate,
    websiteLink,
    whitelist,
    softcap,
    hardcap,
    minimumBuy,
    maximumBuy,
    refundType,
    router,
    liquidity,
    listingRate,
    startTime,
    endTime,
    address: tokenAddress,
    discordLink,
    description,
    telegramLink,
    liquidityLockup,
  };

  const presaleData: ProjectDTO = {
    name: tokenName,
    symbol: tokenSymbol,
    address: tokenAddress,
    approved: approved,
    bought: 0,
    presaleRate: presaleRate,
    cyclePeriod: 0,
    decimals: tokenDecimal,
    description: description,
    discord: discordLink,
    ended: false,
    endTime: endTime,
    hardcap: hardcap,
    facebook: facebookLink,
    firstRelease: 0,
    github: githubLink,
    instagram: instagramLink,
    liqLockTime: liquidityLockup,
    liqPercent: liquidity,
    listingRate: listingRate,
    logo: logoUrl,
    maxBNB: maximumBuy,
    minBNB: minimumBuy,
    participants: 0,
    telegram: telegramLink,
    twitter: twitterLink,
    website: websiteLink,
    whitelist: whitelist,
    startTime: startTime,
    softcap: softcap,
    reddit: redditLink,
    refundType: refundType,
    tokenPerCycle: 0,
    vestEnabled: false,
    admin_wallet: wallet

  };

  const config = {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
  };

  axios({
    withCredentials: false,
  });

  const createPresale = () => {
    axios
      .post(`${baseUrl}/projects/save`, presaleData, config)
      .then((data) => {
        console.log({ data });
      })
      .catch((error) => {
        console.log({ error });
      });
  };
  const navigate = useNavigate();
  const redirectToList = () => {
    navigate("/Presale_list");
  };

  return (
    <div className="submit_page">
      <Navbar />
      <Sidebar />
      <ToastContainer position="top-right" />

      <main className="page_main">
        <div className="items_wrapper">
          <div className="form_section">
            <div className="wrapper">
              <div className="row">
                <span className="property">Token Name</span>
                <span className="value">{tokenName}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Token Symbol</span>
                <span className="value">{tokenSymbol}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Token Decimals</span>
                <span className="value">{tokenDecimal}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Presale rate</span>
                <span className="value">{presaleRate}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Listing Rate</span>
                <span className="value">{listingRate}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Softcap</span>
                <span className="value">{softcap}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>
              <div className="row">
                <span className="property">Website</span>
                <span className="value">{websiteLink}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>
              <div className="row">
                <span className="property">Telegram</span>
                <span className="value">{telegramLink}</span>
              </div>
              <div className="rule_mobile">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>
            </div>
          </div>

          <div className="form_section">
            <div className="wrapper">
              <div className="row">
                <span className="property">Hardcap</span>
                <span className="value">{hardcap}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Minimum buy</span>
                <span className="value">{minimumBuy}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Maximum buy</span>
                <span className="value">{maximumBuy}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Liquidity</span>
                <span className="value">{liquidity}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Start time</span>
                <span className="value">{startTime}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">End time</span>
                <span className="value">{endTime}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>

              <div className="row">
                <span className="property">Liquidity lockup time</span>
                <span className="value">{liquidityLockup}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>
              <div className="row">
                <span className="property">Discord</span>
                <span className="value">{discordLink}</span>
              </div>
              <div className="rule">
                <hr className="ruler" />
                <div className="ring"></div>
              </div>
              <div className="row">
                <span className="property">Description</span>
                <span className="value">{description}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="form_buttons">
          {approved ? (
            <>
              <Link className="react_link" to="" onClick={scrollToTop}>
                <button style={{ marginRight: "40px" }}>Back</button>
              </Link>
              <Link className="react_link" to="/Presale_list">
                <button style={{ background: "green" }}>
                  View Presale List
                </button>
              </Link>
            </>
          ) : (
            <>
              <Link className="react_link" to="" onClick={scrollToTop}>
                <button style={{ marginRight: "40px" }}>Back</button>
              </Link>
              <button onClick={create_presale}>Submit</button>
            </>
          )}
        </div>
      </main>
    </div>
  );
};

export default Submit_Page;
