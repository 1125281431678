import { Link } from "react-router-dom"
import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./MyLocksList.css"
import { ethers } from "ethers"
import LockABI from "../../ABIs/Lock.json"
import STANDARDTOKEN_ABI from "../../ABIs/StandardToken.json";
import { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const MyLocksList = () => {
    //const locks = ["x"]


    const [locks, setLocks] = useState([]);
  const [isUserConnected, setIsUserConnected] = useState<Boolean>(false)
    const [userWallet, setUserWallet] = useState<any>("")
    const [userLockExists, setUserLockExists] = useState<Boolean>(false);

    const [allTokenDetails, setAllTokenDetails]: Array<any> = useState([]);

    const LOCK_CONTRACT_ADDRESS = "0x13c31221f4be9aa5c73a93f2618747adfb23d46d"
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner()
    //console.log(signer)

    const getuserAddress = async () => {
        
        const signerAddress = await signer.getAddress()
        const shortAddress = signerAddress.substring(0, 15) + " ......."
        //console.log(signerAddress)
        setUserWallet(signerAddress);
    }
    getuserAddress()
console.log(userWallet)
    const handleGetAllNormalUserLocks = (userAddress: any) => {
        if (userAddress.trim() === "") {
            return
        }
        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                const userElocks = await eLockContract.normalLocksForUser(userAddress)
                resolve(userElocks)

            }
            catch (error) {
                console.log(error)
                reject([])
            }
        })
    }
    const getTokenDetails = (tokenAddress: any)=>{
        return new Promise(async (resolve,reject)=>{
            const tokenContract =  new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
            console.log(tokenContract)
            const tokenNameReq =  tokenContract.name()
            const tokenSymbolReq =  tokenContract.symbol()
            const tokenDecimalsReq = tokenContract.decimals()
    
            try{
                const tokenDetailsPromise = await Promise.all([tokenNameReq, tokenSymbolReq, tokenDecimalsReq])
    
                const response =  {
                    tokenAddress : tokenAddress,
                    tokenName : tokenDetailsPromise[0],
                    tokenSymbol : tokenDetailsPromise[1],
                    tokenDecimals : Number(tokenDetailsPromise[2].toString())
                }
        
                resolve(response)
            }
            catch(error){
                console.log(error )
                reject(false)
            }
          
            
        })
    }
    const handleTokenData = async (addressDetailToFetch: any) => {
        const promiseData: any = [];
    
        addressDetailToFetch.map((eachAddress:any) => {
          promiseData.push(getTokenDetails(eachAddress));
        });
    
        try {
          const promiseResponse = await Promise.all(promiseData);
    
          console.log(promiseResponse);
    
          let tokenDetails: any = {};
    
          promiseResponse.map((eachTokenData: any) => {
            let tokenData = {
              name: eachTokenData.tokenName,
              symbol: eachTokenData.tokenSymbol,
              decimals: eachTokenData.tokenDecimals,
            };
    
            tokenDetails[eachTokenData.tokenAddress] = tokenData;
          });
    
          setAllTokenDetails({ ...tokenDetails });
    
          // console.log(tokenDetails);
    
          // setAllTokenDetails([...promiseResponse])
        } catch (error) {
          console.log(error);
        }
      };
    
      const handleGetUserLocks = async () => {
    
        try {
          const userNormalLocks: any = handleGetAllNormalUserLocks(userWallet);
    
          const promiseData: any = await Promise.all([userNormalLocks]);
    
          console.log(promiseData);
    
          const allLocks: any = promiseData;
    
          console.log(allLocks);
    
          const allTokensToFetchData: Array<any> = [];
    
          allLocks.map((eachLock: any) => {
            if (allTokensToFetchData.includes(eachLock.token)) {
              return;
            }
            allTokensToFetchData.push(eachLock.token);
            return;
          });
    
          console.log(allTokensToFetchData);
    
          setLocks(allLocks);
    
          if (allLocks.length > 0) {
            handleTokenData(allTokensToFetchData);
            setUserLockExists(true);
          }
        } catch (err) {
          console.log(err);
          toast("There was an error fetching your Locks");
        }
      };
    
      useEffect(() => {
        if (userWallet.trim() !== "") {
          handleGetUserLocks();
        }
      }, [userWallet]);
    

    return (
        <div className="my_locks_list">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right" />
            <main className="page_wrapper">
                <div className="locks_wrapper">
                    {(locks.length == 0) && <span className="nl">
                        You have no lock(s)</span>}

                    {locks.length > 0 && <>
                        {locks.map((eachLock: any, index: any) => {
                            return(
                        <div className="lock_card" key={index}>
                            <span className="title">Lock info</span>
                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Token</span>
                                <span className="r">{allTokenDetails[eachLock.token] === undefined
                            ? ""
                            : allTokenDetails[eachLock.token].name}</span>
                            </div>

                            <hr className="rule" />
                            <div className="card_row">
                                <span className="l">Amount</span>
                                <div className="r adr">
                                {" "}
                        {allTokenDetails[eachLock.token] === undefined
                          ? eachLock.amount.toString()
                          : eachLock.amount.toString() /
                            Math.pow(
                              10,
                              allTokenDetails[eachLock.token].decimals
                            )}
                            {" "}
                        {allTokenDetails[eachLock.token] === undefined
                          ? ""
                          : allTokenDetails[eachLock.token].name}
                          </div>
                            </div>

                            <hr className="rule" />
                            <Link className="react_link" to={`/my_locks/${eachLock.id}`}>
                                <div className="view">View</div>
                            </Link>

                        </div>
                            )
                        })
                    }
                        

                        
                        
                    </>}
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default MyLocksList