import { Routes, Route, BrowserRouter } from "react-router-dom";
import Admin from "../pages/Admin/Admin";
import Airdrop from "../pages/Airdrop/Airdrop";
import CreateLock from "../pages/CreateLock/CreateLock";
import CreateToken from "../pages/CreateToken/CreateToken";
import Home from "../pages/Homepage/Home"
import LaunchPage from "../pages/LaunchPage/LaunchPage";
import MyLocks from "../pages/MyLocks/MyLocks";
import MyLocksList from "../pages/MyLocksList/MyLocksList";
import Page1 from "../pages/Presale/Page_1/Page1";
import Page_2 from "../pages/Presale/Page_2/Page_2";
import Page_3 from "../pages/Presale/Page_3/Page_3";
import Submit_Page from "../pages/Presale/Submit_Page/Submit_Page";
import PresaleList from "../pages/PresaleList/PresaleList";
import MenuProvider from "./context/MenuContext";
import PresaleProvider from "./context/PresaleContext";
import { WalletContextProvider } from "./context/walletContext";

const Views = () => {
    return (
        <WalletContextProvider>
        <MenuProvider>
            <PresaleProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<Home />} />
                        <Route path="/Create_presale" element={<Page1 />} />
                        <Route path="/Create_presale_info" element={<Page_2 />} />
                        <Route path="/Create_presale_social_media" element={<Page_3 />} />
                        <Route path="/Create_presale_submit" element={<Submit_Page />} />
                        <Route path="/launch/:id" element={<LaunchPage />} />
                        <Route path="/Create_token" element={<CreateToken />} />
                        <Route path="/Create_lock" element={<CreateLock />} />
                        <Route path="/Airdrop" element={<Airdrop />} />
                        <Route path="/Presale_list" element={<PresaleList />} />
                        <Route path="/Admin" element={<Admin />} />
                        <Route path="/My_lock_info" element={<MyLocks />} />
                        <Route path="/my_locks_list" element={<MyLocksList />} />
                    </Routes>
                </BrowserRouter>
            </PresaleProvider>
        </MenuProvider>
    </WalletContextProvider>
    )
}

export default Views