import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { ethers } from "ethers";
import Web3Modal from "web3modal"


const providerOptions = {
    coinbasewallet: {
     package: CoinbaseWalletSDK, 
     options: {
       appName: "CoinClass",
       infuraId: process.env.INFURA_KEY 
     }
   },
   walletconnect: {
     package: WalletConnectProvider, 
     options: {
       infuraId: process.env.INFURA_KEY 
     }
   }
}
  
const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions // required
})

const connectWallet = async () => {
    const provider = await web3Modal.connect();
    const connection = new ethers.providers.Web3Provider(provider)
    const signer = await connection.getSigner()
    const signerAddress = await signer.getAddress()
    const shortAddress = signerAddress.substring(0, 15) + " ......."
    return {
        signer,
        signerAddress,
        provider: connection,
    }
}

export interface ConnectInterface {
        signer: any;
        signerAddress: any;
        provider: any;
}

export default {
    connectWallet
}