import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./MyLocks.css"
import { ethers } from "ethers"
import LockABI from "../../ABIs/Lock.json"
import STANDARDTOKEN_ABI from "../../ABIs/StandardToken.json";
import { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



const MyLocks = () => {
    const [userLockExists, setUserLockExists] = useState<Boolean>(false);

  const [allTokenDetails, setAllTokenDetails] = useState<any>([]);

  const [locks, setLocks] = useState([]);
  const [isUserConnected, setIsUserConnected] = useState<Boolean>(false)
    const [userWallet, setUserWallet] = useState<any>("")


    const LOCK_CONTRACT_ADDRESS = "0x13c31221f4be9aa5c73a93f2618747adfb23d46d"
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner()

    const getuserAddress = async () => {
        
        const signerAddress = await signer.getAddress()
        const shortAddress = signerAddress.substring(0, 15) + " ......."
        console.log(signerAddress)
        setUserWallet(signerAddress);
    }
    getuserAddress()

    const handleGetAllNormalUserLocks = (userAddress: any) => {
        if (userAddress.trim() === "") {
            return
        }
        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                const userElocks = await eLockContract.normalLocksForUser(userAddress)
                resolve(userElocks)

            }
            catch (error) {
                console.log(error)
                reject([])
            }
        })
    }
    const getLockInfoById = (lockId: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                const lockDetails = await eLockContract.getLockById(lockId)

                resolve(lockDetails)
            }
            catch (error: any) {
                console.log(error)
                reject({})
            }


        })
    }

    const unLockUserLock = (lockId: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                await eLockContract.unlock(lockId)

                resolve(true)
            }
            catch (error: any) {
                console.log(error)
                if (error.data !== undefined) {
                    reject(error.data.message)
                } else {
                    reject("An Error occured")
                }
            }
        })
    }
    const getTokenDetails = (tokenAddress: any)=>{
        return new Promise(async (resolve,reject)=>{
            const tokenContract =  new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
            console.log(tokenContract)
            const tokenNameReq =  tokenContract.name()
            const tokenSymbolReq =  tokenContract.symbol()
            const tokenDecimalsReq = tokenContract.decimals()
    
            try{
                const tokenDetailsPromise = await Promise.all([tokenNameReq, tokenSymbolReq, tokenDecimalsReq])
    
                const response =  {
                    tokenAddress : tokenAddress,
                    tokenName : tokenDetailsPromise[0],
                    tokenSymbol : tokenDetailsPromise[1],
                    tokenDecimals : Number(tokenDetailsPromise[2].toString())
                }
        
                resolve(response)
            }
            catch(error){
                console.log(error )
                reject(false)
            }
          
            
        })
    }
    const handleTokenData = async (addressDetailToFetch: any) => {
        const promiseData: any = [];
    
        addressDetailToFetch.map((eachAddress:any) => {
          promiseData.push(getTokenDetails(eachAddress));
        });
    
        try {
          const promiseResponse = await Promise.all(promiseData);
    
          console.log(promiseResponse);
    
          let tokenDetails: any = {};
    
          promiseResponse.map((eachTokenData: any) => {
            let tokenData = {
              name: eachTokenData.tokenName,
              symbol: eachTokenData.tokenSymbol,
              decimals: eachTokenData.tokenDecimals,
            };
    
            tokenDetails[eachTokenData.tokenAddress] = tokenData;
          });
    
          setAllTokenDetails({ ...tokenDetails });
    
          // console.log(tokenDetails);
    
          // setAllTokenDetails([...promiseResponse])
        } catch (error) {
          console.log(error);
        }
      };
    
      const handleGetUserLocks = async () => {
        console.log(userWallet);
    
        try {
          const userNormalLocks: any = handleGetAllNormalUserLocks(userWallet);
    
          const promiseData: any = await Promise.all(userNormalLocks);
    
          console.log(promiseData);
    
          const allLocks: any = promiseData;
    
          console.log(allLocks);
    
          const allTokensToFetchData: any = [];
    
          allLocks.map((eachLock: any) => {
            if (allTokensToFetchData.includes(eachLock.token)) {
              return;
            }
    
            allTokensToFetchData.push(eachLock.token);
          });
    
          console.log(allTokensToFetchData);
    
          setLocks(allLocks);
    
          if (allLocks.length > 0) {
            handleTokenData(allTokensToFetchData);
            setUserLockExists(true);
          }
        } catch (error) {
          console.log(error);
          toast("There was an error fetching your Locks");
        }
      };
    
      useEffect(() => {
        if (userWallet.trim() !== "") {
          handleGetUserLocks();
        }
      }, [userWallet]);
    

    return (
        <div className="my_locks">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right" />
            <main className="locks_wrapper">
                <div className="lock_card left">
                    <span className="title">Lock info</span>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Total Amount Locked</span>
                        <span className="r">3,000,000,000</span>
                    </div>

                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Token Address</span>
                        <span className="r adr">0xe327eD2037F133cy66568B6b7b4Dfa6175</span>
                    </div>

                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Token Name</span>
                        <span className="r">Coinclass</span>
                    </div>

                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Token Symbol</span>
                        <span className="r">Coin</span>
                    </div>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Token Decimals</span>
                        <span className="r">12</span>
                    </div>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Owner</span>
                        <span className="r adr">0xe327eD2037F133cy66568B6b7b4Dfa6175</span>
                    </div>
                    <hr className="rule" />
                </div>

                <div className="lock_card">
                    <span className="title">Lock Records</span>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Amount</span>
                        <span className="r">3,000,000,000</span>
                    </div>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Lock Time</span>
                        <span className="r">2023-12-18 15:54</span>
                    </div>
                    <hr className="rule" />
                    <div className="card_row">
                        <span className="l">Unlock Time</span>
                        <span className="r">2023-12-18 15:54</span>
                    </div>
                    <hr className="rule" />

                    <div className="unlock">Unlock</div>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default MyLocks