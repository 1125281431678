"use strict";
exports.__esModule = true;
exports.handleSidebarVisibility = exports.scrollToTop = exports.enablePageScroll = exports.disablePageScroll = void 0;
var disablePageScroll = function () {
    document.body.style.overflowY = "hidden";
};
exports.disablePageScroll = disablePageScroll;
var scrollToTop = function () {
    window.scrollTo(0, 0);
};
exports.scrollToTop = scrollToTop;
var enablePageScroll = function () {
    document.body.style.overflowY = "scroll";
    scrollToTop();
};
exports.enablePageScroll = enablePageScroll;
var handleSidebarVisibility = function (setSidebarVisible, setMenuOpen) {
    window.addEventListener("resize", function () {
        if (window.innerWidth > 1000) {
            setSidebarVisible(false);
            setMenuOpen(false);
        }
        else {
            setSidebarVisible(true);
        }
    });
};
exports.handleSidebarVisibility = handleSidebarVisibility;
