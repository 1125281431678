import Footer from "../../components/Footer/Footer"
import Navbar from "../../components/Navbar/Navbar"
import Sidebar from "../../components/Sidebar/Sidebar"
import "./CreateLock.css"
import copyIcon from "../../assets/icons/copy.svg"
import { useNavigate } from "react-router-dom"
import { ethers } from "ethers"
import LockABI from "../../ABIs/Lock.json"
import STANDARDTOKEN_ABI from "../../ABIs/StandardToken.json"
import { useState, useEffect } from "react"
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const CreateLock = () => {
    const [token, setToken] = useState("");
    const [amount, setAmount] = useState(0);
    const [time, setTime] = useState("");
    const [balance, setBalance] = useState<any>('');
    const [isLiquidtyToken, setIsLiquidityToken] = useState<Boolean>(false);
    const [isUserConnected, setIsUserConnected] = useState<Boolean>(false)
    const [userWallet, setUserWallet] = useState<any>("")
    useEffect(() => { }, [userWallet]);
    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner()
    const getuserAddress = async () => {
        
        const signerAddress = await signer.getAddress()
        const shortAddress = signerAddress.substring(0, 15) + " ......."
        console.log(signerAddress)
        setUserWallet(signerAddress);
    }
    getuserAddress()

    const LOCK_CONTRACT_ADDRESS = "0x13c31221f4be9aa5c73a93f2618747adfb23d46d"

    const handleCreateLock = async (tokenAddress: any, ownerAddress: any,
        isLpToken: any, amount: any, unlockDate: any, description: any, decimals: any) => {

        return new Promise(async (resolve, reject) => {

            try {

                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)



                const eLockRes = await eLockContract.lock(ownerAddress, tokenAddress, isLpToken, BigInt(amount * Math.pow(10, decimals)), unlockDate, description)

                console.log(eLockRes)

                resolve(true)
            }

            catch (error) {
                console.log(error)
                reject(false)
            }

        })

    }

    const handleGetAllLPLocksForUser = (userAddress: any) => {
        if (userAddress.trim() === "") {
            return
        }

        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                const userElocks = await eLockContract.lpLocksForUser(userAddress)
                resolve(userElocks)
            }
            catch (error: any) {
                console.log(error)
                reject([])
            }
        })
    }

    
    const renounceLockOwnerShip = (lockId: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const eLockContract = new ethers.Contract(LOCK_CONTRACT_ADDRESS, LockABI, signer)

                await eLockContract.renounceLockOwnership(lockId)

                resolve(true)
            }
            catch (error: any) {
                console.log(error)
                if (error.data !== undefined) {
                    reject(error.data.message)
                } else {
                    reject("An Error occured")
                }
            }
        })
    }
    const getTokenBalance = async (tokenAddress: any, userAddress: any) => {
        console.log(tokenAddress, userAddress)
        return new Promise(async (resolve, reject) => {
            try {
                const tokenContract = new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)
                const getDecimals = tokenContract.decimals()
                const userBalanceRes = tokenContract.balanceOf(userAddress)
                const promiseData = await Promise.all([userBalanceRes, getDecimals])
                console.log(promiseData)
                const userBalance = promiseData[0].toString() / Math.pow(10, parseInt(promiseData[1].toString()))
                console.log(userBalance)
                resolve(userBalance)
            }

            catch (error) {
                console.log(error)
                reject(0)

            }

        })
    }
    const approveToken = (spenderAddress: any, amount: any, tokenAddress: any) => {
        return new Promise(async (resolve, reject) => {
            try {
                const tokenContract = new ethers.Contract(tokenAddress, STANDARDTOKEN_ABI.abi, signer)

                const getDecimals = await tokenContract.decimals()


                const parsedAmount = BigInt(amount * Math.pow(10, getDecimals.toString()))

                const result = await tokenContract.approve(spenderAddress, parsedAmount)

                await result.wait()

                resolve([true, getDecimals.toString()])
            }

            catch (error: any) {
                console.log(error)
                let erroMessage = ""
                if (error.message !== undefined) {
                    erroMessage = error.message
                }
                reject([false, erroMessage])
            }

        })
    }
    //start
    const handleSetMaxBalance = async () => {
        if (balance !== null) {
            setAmount(balance);
            return;
        }

        const loadingToast = toast.loading("Loading Balance....");

        if (
            token.trim() === "" ||
            userWallet.trim() === "" ||
            isUserConnected === false
        ) {
            alert("You need to put in token address first / you are not connected ");
            return;
        }

        try {
            const userBalance: any = await getTokenBalance(token, userWallet);
            toast.update(loadingToast, { isLoading: false, autoClose: 100 });
            setBalance(userBalance);
            setAmount(userBalance);
        } catch (error) {
            // console.log(error);
        }
    };

    const handleCreateLockClick = async () => {
        if (token.trim() === "") {
            toast("Token Field is empty");
            return;
        }

        if (amount <= 0) {
            toast("Amount cannot O or below ");
            return;
        }


        if (time.trim() === "") {
            toast("Time is invalid, please change the time ");
            return;
        }

        // console.log(time);

        const loadingToast = toast.loading("Approving Tokens for Lock ....");

        try {
            const approveTokenRes: any = await approveToken(
                LOCK_CONTRACT_ADDRESS,
                amount,
                token
            );

            // console.log("Approve Res:", approveTokenRes);

            toast.update(loadingToast, {
                render: "Creating Lock...",
                autoClose: 1200,
                isLoading: true,
            });

            const unixTime = new Date(time).getTime() / 1000;

            // console.log(unixTime);

            const eLockRes = await handleCreateLock(
                token,
                userWallet,
                isLiquidtyToken,
                amount,
                unixTime,
                "First Lock",
                approveTokenRes[1]
            );

            toast.update(loadingToast, {
                autoClose: 1000,
                render: "Token Lock Created",
                isLoading: false,
                type: "success",
            });
            redirectToList()
        } catch (err) {
             console.log(err);
            toast.update(loadingToast, {
                autoClose: 1000,
                render: "An issue occured while creating token lock ",
                isLoading: false,
                type: "error",
            });
        }
    };
    const navigate = useNavigate();
  const redirectToList = () => {
    navigate("/my_locks");
  };


    return (
        <div className="create_lock">
            <Navbar />
            <Sidebar />
            <ToastContainer position="top-right" />
            <form className="lock_form">
                <input type="text"
                    onChange={(e) => setToken(e.target.value)}
                    value={token}
                    placeholder="Token or LP Token Address" />
                <div className="input_group">
                    <span>Amount</span>
                    <input type="text"
                        onChange={(e: any) => setAmount(e.target.value)}
                        value={amount}
                        placeholder="Enter Amount" />
                </div>

                <div className="input_group">
                    <span>Lock Until:</span>
                    <input type="datetime-local"
                        name=""
                        id=""
                        onChange={(e) => setTime(e.target.value)}
                        value={time} />
                </div>

                <div className="note">
                    <span>Exclude Coinclass’s lock address</span> <span className="adr">0xe327eD2037F133cy66568B6b7b4Dfa6175
                    </span><img src={copyIcon} alt="Copy icon" /> <span>from Fees, Max Transaction and Rewards.</span>
                </div>

                <div className="react_link">
                    <div className="submit" onClick={() => handleCreateLockClick()}>Lock</div>
                </div>
            </form>
            <Footer />
        </div>
    )
}

export default CreateLock