import { useState, createContext } from "react";

export const PresaleContext = createContext<any>(null);
const PresaleProvider = ({ children }: any) => {
  const [tokenAddress, setTokenAddress] = useState<String>("");
  const [tokenName, setTokenName] = useState<String>("");
  const [tokenDecimal, setTokenDecimal] = useState<String>("");
  const [tokenSymbol, setTokenSymbol] = useState<String>("");
  const [presaleRate, setPresaleRate] = useState<Number>();
  const [whitelist, setWhitelist] = useState<Boolean>(false);
  const [softcap, setSoftcap] = useState<Number>();
  const [hardcap, setHardcap] = useState<Number>();
  const [minimumBuy, setMinimumBuy] = useState<Number>();
  const [maximumBuy, setMaximumBuy] = useState("");

  const [refundType, setRefundType] = useState<String>("");
  const [router, setRouter] = useState<String>("");
  const [liquidity, setLiquidity] = useState<Number>();
  const [listingRate, setListingRate] = useState<Number>();
  const [startTime, setStartTime] = useState<String>();
  const [endTime, setEndTime] = useState<String>();
  const [liquidityLockup, setLiquidityLockup] = useState<Number>();

  const [logoUrl, setLogoUrl] = useState<String>("");
  const [websiteLink, setWebsiteLink] = useState<String>("");
  const [twitterLink, setTwitterLink] = useState<String>("");
  const [instagramLink, setInstagramLink] = useState<String>("");
  const [facebookLink, setFacebookLink] = useState<String>("");
  const [telegramLink, setTelegramLink] = useState<String>("");
  const [githubLink, setGithubLink] = useState<String>("");
  const [discordLink, setDiscordLink] = useState<String>("");
  const [redditLink, setRedditLink] = useState<String>("");
  const [description, setDescription] = useState<String>("");
  const [vesting, setVesting] = useState<Boolean>(false);
  const [firstReleaseForPresale, setFirstReleaseForPresale] = useState<Number>();
  const [vestingPeriodEachCycle, setVestingPeriodEachCycle] = useState<Number>();
  const [presaleTokenReleaseEachCycle, setPresaleTokenReleaseEachCycle] = useState<Number>();


  return (
    <PresaleContext.Provider
      value={{
        vesting, setVesting,
        firstReleaseForPresale, setFirstReleaseForPresale,
        vestingPeriodEachCycle, setVestingPeriodEachCycle,
        presaleTokenReleaseEachCycle, setPresaleTokenReleaseEachCycle,
        tokenAddress,
        setTokenAddress,
        tokenName, 
        setTokenName,
        tokenDecimal, 
        setTokenDecimal,
        tokenSymbol, 
        setTokenSymbol,
        presaleRate,
        setPresaleRate,
        whitelist,
        setWhitelist,
        softcap,
        setSoftcap,
        hardcap,
        setHardcap,
        minimumBuy,
        setMinimumBuy,
        maximumBuy,
        setMaximumBuy,
        refundType,
        setRefundType,
        router,
        setRouter,
        liquidity,
        setLiquidity,
        listingRate,
        setListingRate,
        startTime,
        setStartTime,
        endTime,
        setEndTime,
        liquidityLockup,
        setLiquidityLockup,
        logoUrl,
        setLogoUrl,
        websiteLink,
        setWebsiteLink,
        twitterLink,
        setTwitterLink,
        instagramLink,
        setInstagramLink,
        facebookLink,
        setFacebookLink,
        telegramLink,
        setTelegramLink,
        githubLink,
        setGithubLink,
        discordLink,
        setDiscordLink,
        redditLink,
        setRedditLink,
        description,
        setDescription,
      }}
    >
      {children}
    </PresaleContext.Provider>
  );
};

export default PresaleProvider;
