import "./Navbar.css"
import logoImg from "../../assets/logos/logo.svg"
import presaleIcon from "../../assets/icons/presale.svg"
import tokenIcon from "../../assets/icons/token.svg"
import airdropIcon from "../../assets/icons/airdrop.svg"
import docIcon from "../../assets/icons/doc.svg"
import lockIcon from "../../assets/icons/create_lock.svg"
import menuIcon from "../../assets/icons/menu.svg"
import iconH from "../../assets/icons/iconH-bl.svg"
import { MenuContext } from "../../setup/context/MenuContext"
import PageContext from "../../setup/context/PageContext"
import DataContext from "../../setup/context/DataContext"
import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom"
import { ethers } from "ethers"
import Web3Modal from "web3modal"
import CoinbaseWalletSDK from "@coinbase/wallet-sdk"
import WalletConnectProvider from "@walletconnect/web3-provider"
import WalletContext from "../../setup/context/walletContext"
import { disablePageScroll } from "../../helpers/setPageScroll"




//There are two connect wallet button in this module, one for mobile and one for desktop

const Navbar = () => {

    const { wallet, setWallet } = useContext(WalletContext)

    const { menuOpen, setMenuOpen } = useContext(MenuContext)
    const [userInfo, setUserInfo] = useState({
        address: "Connect",
    })

    const providerOptions = {
        /* See Provider Options Section */
        coinbasewallet: {
            package: CoinbaseWalletSDK,
            options: {
                appName: "CoinClass",
                infuraId: process.env.INFURA_KEY
            }
        },
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                infuraId: process.env.INFURA_KEY
            }
        }
    }

    const web3Modal = new Web3Modal({
        network: "mainnet", // optional
        cacheProvider: true, // optional
        providerOptions // required
    })

    const connectWallet = async () => {
        const provider = await web3Modal.connect();
        const connection = new ethers.providers.Web3Provider(provider)
        const signer = await connection.getSigner()
        const signerAddress = await signer.getAddress()
        const shortAddress = signerAddress.substring(0, 15) + " ......."

        setUserInfo({
            address: shortAddress,
        })

        setWallet(signerAddress);
    }

    useEffect(() =>{
        connectWallet()
    })
    
    

    return (
        <div className="navbar">
            <Link className="react_link" to="/">
                <div className="logo">
                    <img src={logoImg} alt="Coinclass logo" />
                </div>
            </Link>

            <div className="connect_btn_wrapper desktop">
                <div className="btn" onClick={connectWallet}>
                    {userInfo.address}
                </div>

            </div>

            <div className="navlinks_or_menuBtn">
                <ul className="navlinks">
                    <li>
                        <Link className="react_link" to="/My_locks_list">
                            <div className="items">
                                <div className="icon">
                                    <img src={lockIcon} alt="Lock icon" />
                                </div>
                                <span className="text">My lock(s)</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/Create_lock">
                            <div className="items">
                                <div className="icon">
                                    <img src={lockIcon} alt="Lock icon" />
                                </div>
                                <span className="text">Create lock</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/Create_presale">
                            <div className="items">
                                <div className="icon">
                                    <img src={iconH} alt="Presale icon" />
                                </div>
                                <span className="text">Create Presale</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/Presale_list">
                            <div className="items">
                                <div className="icon">
                                    <img src={presaleIcon} alt="Presale icon" />
                                </div>
                                <span className="text">Presale List</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/Create_token">
                            <div className="items">
                                <div className="icon">
                                    <img src={tokenIcon} alt="Create Token icon" />
                                </div>
                                <span className="text">Create Token</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/Airdrop">
                            <div className="items">
                                <div className="icon">
                                    <img src={airdropIcon} alt="Airdrop icon" />
                                </div>
                                <span className="text">Airdrop</span>
                            </div>
                        </Link>
                    </li>

                    <li>
                        <Link className="react_link" to="/">
                            <div className="items">
                                <div className="icon">
                                    <img src={docIcon} alt="Doc icon" />
                                </div>
                                <span className="text">Doc</span>
                            </div>
                        </Link>
                    </li>

                    <div className="connect_btn_wrapper">
                        <div className="btn" onClick={connectWallet}>{userInfo.address}</div>
                    </div>

                </ul>


                <div className="menu" onClick={() => { setMenuOpen(!menuOpen); disablePageScroll() }}>
                    <div className="menu_icon">
                        <img src={menuIcon} alt="Menu icon" />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar