import { useState, createContext } from "react";

export const WalletContext = createContext();

export const WalletContextProvider = ({ children }) => {
  const [wallet, setWallet] = useState('');
  const [tokenOnSale, setKokenOnSale] = useState(false);
  return (
    <WalletContext.Provider value={{ wallet, setWallet, tokenOnSale, setKokenOnSale }}>
      {children}
    </WalletContext.Provider>
  );
};

export default WalletContext;
